import React from "react"

import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

import MediaguideLeichteSprache from "../../content/shared/mediaguide-leichte-sprache"

const Mediaguide = () => {
  return (
    <Layout backdrop="mediaguide-leichte-sprache">
      <Seo
        title="Media-Guide in Leichter Sprache"
        description="Mit dem Media-Guide erfahren Sie spannende Hintergründe zu den Objekten und Themen der Dauerausstellung. Der Media-Guide ist inklusiv: Er enthält Inhalte in Leichter Sprache und Gebärdensprache, Angebote für blinde und sehbeeinträchtigte Menschen und in den Sprachen Deutsch, Englisch und Französisch."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Media-Guide",
              link: "/mediaguide",
            },
            {
              title: "Leichte Sprache",
              link: "/mediaguide/leichte-sprache",
            },
          ]}
        />
        <PageTitle>Media-Guide in Leichter Sprache</PageTitle>
        <MediaguideLeichteSprache />
      </Stack>
    </Layout>
  )
}

export default Mediaguide
